<template>
  <div>
    <a
      rel="noreferrer"
      target="_blank"
      href="https://assoft.co"
      class="position-absolute  bottom-0 end-0 m-3"
      >Optima Health by Assoft</a
    >
    <form
      @submit.prevent="forgot"
      class="p-3 position-absolute top-50 start-50 translate-middle border rounded public-form"
    >
      <h3 class="text-center">¿Ha Olvidado la Contraseña?</h3>
      <p class="text-center">
        Ingrese su correo electrónico para poder crear una nueva contraseña
      </p>
      <div class="mb-3 inputIcon">
        <label for="forgotEmail" class="form-label">Correo Electrónico</label>
        <input
          autofocus
          type="email"
          class="form-control"
          id="forgotEmail"
          aria-describedby="emailHelp"
          v-model="email"
          required
        />
        <svg class="bi" fill="currentColor">
          <use xlink:href="/img/icons/bootstrap-icons.svg#person" />
        </svg>
      </div>
      <div class="d-grid gap-2">
        <button type="submit" class="btn btn-primary shadow-primary">
          Enviar el Link
        </button>
      </div>
      <p class="mt-3 text-center">
        <router-link to="/forgotPassword/help"
          >No puedo recuperar mi contraseña</router-link
        >
      </p>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import Session from "@/modules/session";

export default {
  data() {
    return {
      email: ""
    };
  },
  name: "ForgotPassword",
  methods: {
    forgot() {
      new Session()
        .forgotPassword(this.email)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Se ha enviado un correo con las indicaciones",
            toast: true,
            position: "bottom",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Correo Electrónico no válido",
            toast: true,
            position: "bottom",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: toast => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
        });
    }
  }
};
</script>
